.slideshow-image-container {
	height: 100vh;
	overflow: hidden;
	position: relative;
	top: 0;
	left: 0;
	width: 100vw;
	margin: auto;
}

 .slideshow-image {
	height: 100%;
	left: 0;
	object-fit: cover;
	object-position: center;
	opacity: 0;
	position: absolute;
	top: 0;
	width: 100%;
	z-index: -1;
}

.slideshow-image.next {
	opacity: 1;
	z-index: 1;
}

.slideshow-image.prev {
	opacity: 1;
	z-index: 2;
}

.slideshow-image.fade-out {
	opacity: 0;
	//transition: visibility 0s 2s, opacity 2s ease-in-out;
	visibility: hidden;
}
