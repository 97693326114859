.alert {
	&.alert-danger {
		font-size: 1rem;
		font-weight: normal;
		text-align: left;
	}
}

.default-color {
	color: #507b7f;
}

.login {
	display: flex;
	align-items: center;

	main {
		margin: 0;
		width: 100%;
	}

	.container {
		width: 100%;
		padding: 0;
		max-width: 100%;
		min-width: 100px !important;
	}
}

#company-logo-login {
	opacity: 0.9;
	position: fixed;
	display: block;
	margin-top: 25px;
	margin-left: 50px;
	width: 180px;
	height: 58px;
}

#login-card {
	border-radius: 20px;
	background-color: white;

	a {
		color: #507b7f;
	}

	button {
		background-color: #507b7f;
	}
}

#reset_password_mail {
	width: 100%;
	border: 1px solid #ced4da;
	border-radius: 0.25rem;
	height: calc(1.5em + 0.75rem + 2px);
	padding: 0.375rem 0.75rem;
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.5;

	&:focus-visible {
		outline: unset !important;
	}

	&:focus {
		color: #495057;
		background-color: #fff;
		border-color: #80bdff;
		outline-color: unset!important;
		box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, .25);
	}
}

#content {
	min-width: initial;
}


