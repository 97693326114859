/* Variables */
@import "variables";

@-webkit-keyframes fadeIn {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

@-moz-keyframes fadeIn {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

@keyframes fadeIn {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

@-webkit-keyframes fadeOut {
	from {
		opacity: 1;
	}
	to {
		opacity: 0;
	}
}

@-moz-keyframes fadeOut {
	from {
		opacity: 1;
	}
	to {
		opacity: 0;
	}
}

@keyframes fadeOut {
	from {
		opacity: 1;
	}
	to {
		opacity: 0;
	}
}

*, *::after, *::before {
	box-sizing: border-box;
}

*::-moz-selection {
	background: #B3D4FC none repeat scroll 0 0;
	text-shadow: none;
}

.dispNone {
	display: none;
}

.fixed {
	position: fixed;
}

.dateRangeSelect-with-clear-container {
	position: relative;
	width: 164px
}

.fltLft {
	float: left;
}

.fa {
	&.fa-baseline-inherit-fontsize {
		font-size: inherit;
		vertical-align: baseline;
		margin-left: 3px;
	}
}

.dispInline {
	display: inline !important;
}

.crsrPntr {
	cursor: pointer;
}

.lbAnywhere {
	line-break: anywhere;
}

.invisible {
	visibility: hidden;
}

button, a {
	&.unlabeled {
		width: 24px;

		& > span {
			display: none;
		}
	}
}

.dev-blocker {
	width: 3000px;
	height: 2000px;
	background-color: rgba(0, 0, 0, 0.1);
	cursor: default;
	position: absolute;
	top: 0;
	left: -30px;
	z-index: 10000 !important;
	text-align: center;
}

.inlnBlock {
	position: relative;
	display: inline-block;
	//vertical-align: top;
	//line-height: 2;
}

input.dateRangeSelect {
	width: 153px;

	&.dateRangeSelect-with-clear {
		width: 164px;
	}
}

.pulledRight {
	float: right;
	margin-right: 15px;
	position: relative;

	& > div {
		float: left;
		margin-left: 5px;
	}
}

.fntsz11px {
	font-size: 11px;
}

.fntsz12px {
	font-size: 12px;
}

.notification-badge {
	margin-left: -10px;
	font-size: 8px;
	margin-top: -10px;
	padding: 3px;
	background-color: @color-inactive;
}

.onePixPad {
	padding: 1px;
}

.p-15 {
	padding: 15px;
}

.pl-15 {
	padding-left: 15px;
}

.pl-30 {
	padding-left: 30px;
}

.table-row-odd {
	background-color: @darkRow-bg;
}

.algn-lft {
	text-align: left !important;
}

.algn-cntr {
	text-align: center !important;
}

.algn-rght {
	text-align: right !important;

}

.btnDiv {
	//padding: 0 0 5px;
	position: absolute;
	top: 5px;
	right: 5px;

	&.bottom {
		bottom: 5px;
		top: unset;
	}
}

.padding-top-6 {
	padding-top: 6px;
}

.margin-top-20 {
	margin-top: 20px !important;
}

.margin-top-25 {
	margin-top: 25px !important;
}

.margin-top-30 {
	margin-top: 30px !important;
}

.margin-bottom-none {
	margin-bottom: 0 !important;
}

.margin-bottom-10 {
	margin-bottom: 10px !important;
}

.margin-bottom-20 {
	margin-bottom: 20px !important;
}

.margin-left-5 {
	margin-left: 5px !important;
}

.margin-left-10 {
	margin-left: 10px !important;
}

.margin-left-20 {
	margin-left: 20px !important;
}

.line-height-inherit {
	line-height: inherit;
}

.hght20 {
	height: 20px;
}

.ovrflw-dots {
	overflow: hidden;
	text-overflow: ellipsis;
}

.no-padding-right {
	padding-right: 0 !important;
}

.no-padding-left {
	padding-left: 0 !important;
}

.table-row {
	padding: 4px 6px;

	&.odd {
		background-color: #F9F9F9;
	}

	.comment-meta {
		font-size: 10px;
		color: @bg-neutral;
	}
}

.full-width {
	width: 100%;

	&.tArea {
		max-width: 100%;
		min-width: 100%;
		min-height: 40px;
	}
}

.no-sorting {
	[role=button] {
		cursor: unset;
	}
}

span, div {
	&.ok {
		color: @color-active;
	}

	&.not-ok, &.discount-alert {
		color: @color-inactive;
	}
}

.alert {
	&.alert-danger {
		font-size: 28px;
		font-weight: bold;
		text-align: center;
	}
}



.inactive {
	color: @color-inactive;
}

.inactive-company {
	color: @border-color-neutral;
	font-style: italic;
}

.flexbox {
	display: flex !important;

	&.fb-right {
		justify-content: flex-end;

		& > * {
			margin-left: 5px;
		}
	}

	&.fb-left {
		justify-content: flex-start;

		& > * {
			margin-right: 5px;
		}
	}

	&.fb-space-between {
		justify-content: space-between;
	}

	h4 {
		margin: 0;
	}

	a {
		&.btn {
			font-size: 11px;
			height: 24px;
			padding-top: 3px;
		}
	}

	button {
		&.btn {
			font-size: 11px;
			height: 24px;
			padding-top: 3px;
		}
	}
}

body {
	background-color: #FFF;
	color: @color-default;
	font-family: Calibri, Tahoma, Helvetica, Arial, sans-serif;
	font-size: 11px;
	line-height: 1.42857;
	margin: 0;
	overflow-y: scroll;

	&.fullscreen {
		background: #FFF none repeat scroll 0 0;
	}

	& > header {
		height: 105px;
		position: fixed;
		top: 0;
		width: 100%;
		z-index: 1001;
		background: @header-bg;

		h1 {
			height: 85px;
			left: 13px;
			margin: 0;
			padding: 0;
			position: absolute;
			top: 11px;
			width: 247px;

			a {
				background-image: @header-logo-url;
				background-repeat: no-repeat;
				display: block;
				height: 85px;
				width: 247px;
			}
		}

		a {
			&:hover {
				text-decoration: none;
			}
		}

		div#globalSearch {
			position: absolute;
			bottom: 0;
			right: 0;
			padding: 5px;

			.ui-select-container {
				top: -1px;
			}
		}
	}

	& > nav.affix {
		top: 0;
	}

	.floatingThumb {
		position: absolute;
		-webkit-box-shadow: 2px 2px 7px 0px rgba(0, 0, 0, 0.7);
		-moz-box-shadow: 2px 2px 7px 0px rgba(0, 0, 0, 0.7);
		box-shadow: 2px 2px 7px 0px rgba(0, 0, 0, 0.7);
	}
}

body.stage {
	& > header {
		h1 {
			a {
				background-image: @stage-logo-url;
			}
		}
	}
}

.pending {
	background-image: url("../img/rolling.gif");
	background-repeat: no-repeat;
	background-position: center;
	min-height: 24px;
}

.bg-r35 {
	background-position-x: ~"calc(100% - 35px)";
}

ui-view {
	position: relative;
}

article, aside, details, figcaption, figure, footer, header, main, nav, section, summary {
	display: block;
}

h1, h2, h3 {
	margin-bottom: 7.5px;
	margin-top: 15px;
}

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
	color: inherit;
	font-family: Tahoma, Helvetica, Arial, sans-serif;
	font-weight: 500;
	line-height: 1.1;

	u {
		text-decoration: none;
		border-bottom: 1px solid #555;
	}
}

.h1, h1 {
	font-size: 28px;
}

.h2, h2 {
	font-size: 23px;
}

ul {
	&.ls-none {
		list-style: none;
	}
}

a {
	outline: 0 none;
	color: @color-default;
	text-decoration: none;
	background: rgba(0, 0, 0, 0) none repeat scroll 0 0;

	&:active, &:hover {
		cursor: pointer;
	}

	&:focus, &:hover {
		color: #2F2F2F;
		text-decoration: underline;
	}

	&.btn {
		pointer-events: inherit;
		padding: 2px 4px;

		&.disabled {
			pointer-events: inherit;
		}
	}

	&.underlined {
		text-decoration: underline;
	}
}

div {
	&.valign {
		height: 100%;
		font-size: 12px;
		display: -webkit-flex;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-align-items: center;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;

		& > div {
			width: 100%;
		}
	}

	&[contenteditable=true] {
		border: @border-color-neutral solid 1px;
	}
}

.centered-stats {
	.fa-sort-asc {
		//margin-top: 3px;
	}

	.fa-sort-desc {
		//margin-top: -8px;
	}
}

.creditor-doc-approval {
	.approval-box {
		border-radius: 4px;
		border: 2px solid;
		margin-top: -9px;

		&.status-0 {
			border-color: @bg-neutral;
		}

		&.status-1,
		&.status-3 {
			border-color: @color-inactive;
		}

		&.status-2 {
			border-color: @warning-bg;
		}

		&.status-4 {
			border-color: @color-active;
		}

		.approval-heading {
			font-weight: bold;
			font-size: 12px;
			padding: 3px 3px 5px 5px;

			&.status-0 {
				background-color: @bg-neutral;
				color: @color-on-dark-bg;
			}

			&.status-1, &.status-3 {
				background-color: @color-inactive;
				color: @color-on-dark-bg;
			}

			&.status-2 {
				background-color: @warning-bg;
			}

			&.status-4 {
				background-color: @color-active;
				color: @color-on-dark-bg;
			}
		}

		.approval-content {
			padding: 0 10px;

			h6 {
				border-bottom: 1px solid @bg-neutral;
				padding-bottom: 3px;
				font-size: 11px;
				font-weight: bold;

				.expander {
					margin-top: -2px;
				}
			}

			ul {
				padding-left: 0;
			}

			li {
				//margin-bottom: 25px;
				&.second-level {
					margin-bottom: 0;
				}

				&:last-child {
					margin-bottom: 5px;
				}

				list-style: none;
			}

			.fa {
				font-size: 16px;

				&.status-1, &.status-3 {
					color: @color-inactive;
				}

				&.status-2, &.status-4 {
					color: @color-active;
				}

				&.fa-undo {
					color: inherit !important;
				}

				&.fa-plus-square-o, &.fa-minus-square-o {
					cursor: pointer;
				}
			}

			textarea {
				resize: vertical;
				height: 100px;
				width: 100%;

				&.ng-invalid {
					border: @color-validation-error solid 1px;
					padding: 2px;
				}
			}
		}
	}
}

.debitor-doc-approval {
	.approval-box {
		border-radius: 4px;
		border: 2px solid;
		border-color: @bg-neutral;

		.approval-heading {
			font-weight: bold;
			font-size: 12px;
			padding: 3px 3px 5px 5px;
		}

		.approval-content {
			padding: 10px;

			h6 {
				border-bottom: 1px solid @bg-neutral;
				padding-bottom: 3px;
				font-size: 11px;
				font-weight: bold;
			}

			p {
				margin: 0;

				&.comment {
					margin: 0 0 10px 10px;
				}
			}

			& > ul {
				padding-left: 0;

				& > li {
					margin-bottom: 25px;

					&.second-level {
						margin-bottom: 0;
					}

					&:last-child {
						margin-bottom: 5px;
					}

					list-style: none;
				}
			}

			textarea {
				resize: vertical;
				height: 100px;
				width: 100%;
			}

			.fa {
				font-size: 16px;

				&.status-approved {
					color: @color-active;
				}

				&.status-rejected {
					color: @color-inactive;
				}

				&.fa-undo {
					color: inherit;
				}
			}
		}

		&.status-approved {
			border-color: @color-active;

			.approval-heading {
				background-color: @color-active;
				color: @color-on-dark-bg;
			}
		}

		&.status-rejected {
			border-color: @color-inactive;

			.approval-heading {
				background-color: @color-inactive;
				color: @color-on-dark-bg;
			}
		}
	}
}

.approval-indicator {
	.fa {
		font-size: 16px;

		&.status-approved {
			color: @color-active;
		}

		&.status-rejected {
			color: @color-inactive;
		}

		&.fa-undo {
			color: inherit;
		}
	}
}

.hint {
	&.error {
		color: @color-validation-error;
	}

	&.ok {
		color: @color-active;
	}
}

/*.clearfix {
	&::after, &::before {
		content: " ";
		display: table;
	}
	&::after {
		clear: both;
	}
}*/

ol, ul {
	margin-bottom: 7.5px;
	margin-top: 0;
}

ul {
	padding-left: 15px;
}

sup {
	&.hint {
		color: @light-bg;
	}
}

span.tag {
	padding: 2px 5px;
	font-size: 9px;
	font-weight: bold;
	border-radius: 5px;
	margin: 0 5px;
	color: @color-on-dark-bg;

	&.active {
		background-color: @color-active;
	}

	&.inactive {
		background-color: @color-inactive;
	}

	&.default {
		background-color: @light-bg;
	}
}

nav {
	position: fixed;
	top: 105px;

	&#mainNav {
		background: @dark-bg none repeat scroll 0 0;
		// border-top: 1px solid @dark-bg;
		box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.3);
		font-size: 9pt;
		font-weight: 700;
		line-height: 1em;
		width: 100%;
		z-index: 1000;

		ul {
			list-style: outside none none;
			padding-left: 0;

			& > li {
				display: inline-block;
				float: left;
				padding-left: 0;
				padding-right: 0;

				&:first-child {
					padding-left: 0;
				}

				&.active {
					& > a {
						background: #FFF none repeat scroll 0 0;
						color: @dark-bg;
					}
				}

				a {
					&:focus, &:hover {
						text-decoration: none;
						//background-color: #EEE;
					}
				}

				&:hover {
					& > a {
						background: @light-bg none repeat scroll 0 0;
						color: @color-on-dark-bg;
					}

					ul {
						box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.3);
						display: block;
					}

					cursor: pointer;
				}

				& > ul {
					background: @dark-bg none repeat scroll 0 0;
					display: none;
					position: absolute;
					z-index: 100;

					& > li {
						display: block;
						float: none;
					}
				}
			}
		}

		a {
			color: #FFF;
		}
	}

	ul {
		list-style: outside none none;
		margin-bottom: 0;
		padding-left: 0;

		&::after, &::before {
			content: " ";
			display: table;
		}

		&::after {
			clear: both;
		}

		& > li {
			display: block;
			position: relative;

			& > a {
				display: block;
				padding: 6px 8px;
				position: relative;
			}
		}
	}
}

main {
	margin: 145px 10px 20px;
	position: relative;
	min-height: 300px;

	.container {
		display: flex;
		position: relative;

		table {
			&.table-striped {
				tr {
					&.odd {
						background-color: #F9F9F9;
					}
				}

				td {
					border-top: none;
				}
			}
		}

		div.balanceTable {
			line-height: 1;

			td {
				padding-left: 20px;
			}
		}
	}

	header {
		margin-bottom: 0.5em;

		&::after, &::before {
			content: " ";
			display: table;
		}

		&::after {
			clear: both;
		}
	}

	.content {
		//position: absolute;
		top: 0;
		left: 5px;
		flex: 1 1 auto;
		order: 0;
		width: 100%;

		.maxWidthDesktop {
			max-width: 75pc !important;
			margin: 0 auto;
		}

		.smallWidthDesktop {
			max-width: 30pc !important;
			margin: 0 auto;
		}
	}
}

#scanFilterMenu {
	position: fixed;
	z-index: 99;
	background: white;
	width: 100%;
	height: 60px;
	padding: 15px;
	top: 130px;
	right: 0;
}

.scanPreviewContainer {
	margin-bottom: 30px;

	.scanPreview {
		border: 2px @color-inactive solid;
		border-radius: 5px;
		padding: 5px;
		height: 230px;

		&.assigned {
			border-color: @color-active;
		}

		img {
			height: 160px;
			margin: auto;
			left: 0;
			right: 0;
			bottom: 0;
			top: 10px;
			position: absolute;
			cursor: url(/img/magnifier.png) 5 5, pointer;
		}

		.containerChecker {
			font-size: 24px;
			float: right;
			cursor: pointer;

			&.fa-check-square {
				color: @color-active;
			}
		}

		&.small {
			height: 200px;

			img {
				height: 140px;

				&.rot90, &.rot270 {
					max-height: 110px;
				}
			}
		}
	}
}

#topNav {
	border: medium none;
	font-size: 11px;
	line-height: 1.15em;
	position: absolute;
	right: 0;
	top: 0;

	.toolTabs, .userAction, .userInfo {
		float: left;
		list-style: outside none none;
		margin: 0 0 0 2em;
		padding: 0;

		&::after, &::before {
			content: " ";
			display: table;
		}

		&::after {
			clear: both;
		}

		& li {
			display: inline-block;
			float: left;
			padding-left: 0;
			padding-right: 0;

			&:first-child {
				padding-left: 0;
			}

		}
	}

	.toolTabs, .userAction {
		li {
			a {
				background: @header-bg none repeat scroll 0 0;
				display: block;
				padding: 6px 8px;
			}

			&.dropdown-notifications:hover > div.dropdown-container {
				display: block;
			}

			.dropdown-container {
				top: 75%;
				width: 300px;
				max-width: 300px;

				.dropdown-toolbar {
					background-color: @dark-bg;
					color: @color-on-dark-bg;

					.dropdown-toolbar-title {
						font-weight: bold;
					}
				}
			}

			ul.dropdown-menu {
				width: 300px;
				max-width: 300px;
				padding: 12px;
				display: block;

				li {
					display: list-item;
					float: none;

					a {
						background: unset;
						text-decoration: none;
						font-size: 11px;

						.header-notification-item {
							font-weight: bold;
						}

						&:hover {
							background-color: @bg-neutral;
							color: @color-on-dark-bg;
						}
					}
				}
			}
		}
	}

	.userInfo {
		display: block;
		padding: 6px 8px;
	}
}

#blocker {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 999998;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	#loading-spinner {
		display: none;
	}

	&.processing {
		background-color: rgba(255, 255, 255, .7);
		animation-name: fadeIn;
		animation-duration: @animation-duration;

		#loading-spinner {
			display: block;
		}

		&.done {
			animation-name: fadeOut;
			animation-duration: @animation-duration;
			animation-fill-mode: forwards;

			#loading-spinner {
				display: none;
			}
		}
	}

	h4 {
		text-align: center;
		margin-top: 40px;
		display: flex;
		justify-content: center;
		align-items: center;
	}
}

.container {
	min-width: 75pc !important;
	width: 100%;
	margin-left: auto;
	margin-right: auto;
	padding-left: 5px;
	padding-right: 5px;

	&::after, &::before {
		content: " ";
		display: table;
	}

	&::after {
		clear: both;
	}
}

.read-only-form-content {
	font-size: 12px;
	line-height: 24px;
	padding-top: 2px;
	padding-left: 22px;

	&.no-padding-left {
		padding-left: 7px !important;

		&.viewMode {
			padding-left: 0 !important;
		}
	}

	&.normal-padding-left {
		padding-left: 15px !important;
	}
}

.docItem {
	border: 1px solid #E5E5E5;
	border-radius: 3px;
	padding: 15px 6px 6px;
	margin-bottom: 10px;
	position: relative;
}

div.docStatusIndicator {
	position: relative;
	border: none;
	width: 32px;
	height: 24px;
	cursor: help;

	div {
		position: absolute;
		width: 14px;
		height: 14px;
		padding: 1px 3px;
		font-size: 10px;
		top: 0;
		left: 1px;

		&.level_1, &.level_2, &.level_3 {
			&.fa-check {
				padding-left: 2.5px;
				//color: @color-active;
			}

			&.fa-ban {
				//color: @color-inactive;
			}
		}

		&.level_2 {
			top: 14px;
		}

		&.level_3 {
			top: 28px;
		}

		&.new {
			background-color: @color-neutral;
		}

		&.status-0 {
			background-color: @color-neutral;
		}

		&.status-1, &.status-3, &.status-5 {
			background-color: @color-inactive;
			color: @color-on-dark-bg;
		}

		&.status--1, &.status--2 {
			background-color: @warning-border;
			color: @color-on-dark-bg;
		}

		&.status-2, &.status-4 {
			background-color: @color-open;
		}

		&.status-4.status-max-2 {
			background-color: @color-active;
			color: @color-on-dark-bg;
		}

		&.status-6 {
			color: @color-on-dark-bg;
			background-color: @color-active;
		}

		&.upper {
			top: 0;

			&.level1, &.level2 {
				background-color: @color-active;
			}

			&.rejected_open_1, &.rejected_open_2 {
				background-color: @color-inactive;
			}
		}

		&.lower {
			top: 11px;

			&.level2 {
				background-color: @color-active;
			}

			&.rejected_open_2 {
				background-color: @color-inactive;
			}
		}
	}

	&.bordered {
		div {
			border: 1px #D4D4D4 solid;
			border-left: none;
			left: 0;
			width: 12px;
			height: 12px;
		}
	}

	i.fa {
		border: none;
		display: block;
		left: 17px;
		top: 5px;
		position: absolute;
		font-size: 14px;

		&.fa-inbox {
			top: 2px;
			font-size: 12px;
		}

		&.fa-envelope {
			top: 0;
			font-size: 11px;
			color: @color-active;
		}

		&.fa-repeat {
			top: 28px;
			font-size: 12px;
		}

		&.fa-lock {
			&.deb {
				top: 14px;
				left: 18px;
			}
		}
	}
}

.label-total {
	font-size: 12px;
	font-weight: bold;
}

.centered-stats {
	margin: 0 auto;
	width: 700px;

	div {
		&.table-header, &.table-footer {
			font-weight: bold;
		}

		&.flex {
			display: flex;
		}

		&.top-dashed {
			border-top: dashed 1px #555555;
		}

		&.bottom-dashed {
			border-bottom: dashed 1px #555555;
		}

		&.text-content {
			padding: 8px 10px;
		}
	}

	.col1 {
		width: 222px;
	}

	.col2 {
		width: 478px;

		.even {
			background-color: @darkRow-bg;
		}

		.col2-1 {
			width: 36px;
			padding: 6px 9px;

			a {
				i {
					font-size: 18px;
				}

				&[aria-expanded=true] {
					i.fa:before {
						content: "\f07c";
					}
				}

				&[aria-expanded=false] {
					i.fa:before {
						content: "\f07b";
					}
				}
			}
		}

		.col2-2 {
			width: 222px;
		}

		.col2-3 {
			width: 110px;
		}

		.col2-4 {
			width: 110px;
		}
	}
}

/* UI-Grid-Gedöns Start*/

.gridHeadline {
	line-height: 1.1;
	font-size: 18px;
	margin-top: 4px;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

input[type=number] {
	-moz-appearance: textfield;
}

.ui-grid {
	.ui-grid-cell, .ui-grid-footer-cell, .ui-grid-header-cell {
		input {
			&.editable-grid-cell {
				border: none;
				padding: 5px;
				//background: @cell-edit-bg;
				text-align: right;
			}
		}

		&.col-turnover {
			background-color: rgba(255, 134, 5, 0.1);
		}

		&.col-forecast {
			background-color: rgba(163, 163, 163, 0.1);
		}

		&.col-current {
			background-color: rgba(0, 134, 5, 0.1);
		}

		//.ui-grid-cell-contents {
		//	&.edited {
		//		background: @cell-edit-bg;
		//	}
		//}
	}

	.ui-grid-row:nth-child(2n) {
		.ui-grid-cell {
			&.col-turnover {
				background-color: rgba(255, 134, 5, 0.2);
			}

			&.col-forecast {
				background-color: rgba(163, 163, 163, 0.25);
			}

			&.col-current {
				background-color: rgba(0, 134, 5, 0.2);
			}
		}

	}

	.ui-grid-cell {
		&.col-forecast {
			&.editable {
				cursor: pointer;
			}
		}
	}

	//.ui-grid-row:nth-child(2n) {
	//	.ui-grid-cell {
	//		input {
	//			&.editable-grid-cell {
	//				background: @cell-edit-bg-dark;
	//			}
	//		}
	//		.ui-grid-cell-contents {
	//			&.edited {
	//				background: @cell-edit-bg-dark;
	//			}
	//		}
	//	}
	//}
	.ui-grid-header-cell {
		line-height: 24px;
		height: 100%;
		display: table-cell;

		.sortable {
			height: 100%;
			cursor: unset;

			.ui-grid-cell-contents {
				height: 100%;
			}
		}

		.ui-grid-invisible {
			display: none;
		}

		.ui-grid-column-menu-button {
			right: 0;
			outline: none;
		}

		.ui-grid-header-cell-primary-focus {
			width: calc(~'100% - 12px');
			outline: none;
		}

		&.no-header {
			background-color: #FFFFFF;
			//border: none;
		}

		&.no-padding {
			.ui-grid-header-cell-primary-focus {
				padding: 0;
			}
		}

		&.full-width {
			.ui-grid-header-cell-primary-focus {
				width: 100%;
			}
		}

		&.wrapping {
			.ui-grid-header-cell-label {
				white-space: normal;
			}
		}

		.ui-grid-cat {
			line-height: 24px;
		}

		sub {
			font-size: 80%;
		}
	}

	&.multiline-cells {
		.ui-grid-row {
			height: auto !important;
		}

		.ui-grid-cell {
			height: 100% !important;
		}
	}

	.numberCell {
		text-align: right;
		-webkit-font-feature-settings: "tnum" 1;
		-moz-font-feature-settings: "tnum" 1;
		-ms-font-feature-settings: "tnum" 1;
		font-feature-settings: "tnum" 1;
	}

	.ui-grid-cell-contents {
		&.button-cell {
			padding: 1px;
		}

		.btn {
			font-size: 11px;
			padding: 2px 4px 3px;

			[class^="fa fa-"] {
				margin-bottom: 0;
			}
		}

		&.thumb-ui-grid-cell {
			position: relative;
			//cursor: pointer;
			cursor: url(/img/magnifier.png) 5 5, pointer;
			padding: 2px;

			img {
				position: absolute;
				height: auto;
				width: auto;
				max-width: 32px;
				max-height: 32px;
				margin: auto;
				top: 0;
				bottom: 0;
				left: 0;
				right: 0;
			}
		}

		.half-cell {
			width: 50%;
			float: left;
		}
	}

	.ui-grid-pager-row-count-picker {
		select {
			font-size: 11px;
			height: 24px;
			padding: 3px 6px;
			width: auto;
			text-align: right;
		}
	}

	.ui-grid-pager-control {
		button {
			font-size: 11px;
			height: 24px;
			padding: 6px 9px;
		}

		input {
			font-size: 11px;
			height: 24px;
			padding: 0 6px;
			text-align: right;
		}
	}

	.ui-grid-pager-panel {
		position: absolute;
		left: 0;
		bottom: auto;
		width: 100%;
		padding-top: 10px;
		padding-bottom: 3px;
		box-sizing: content-box;
	}

	.ui-grid-header-cell-row {
		display: block;

		.ui-grid-header-cell {
			display: table-cell;
		}
	}
}

.ui-grid, .ui-grid-viewport {
	height: auto !important;
	width: 100%;

	.ui-grid-canvas {
		padding-top: 0;
	}

	.ui-grid-row {
		&:hover {
			.ui-grid-cell {
				background-color: rgba(1, 30, 100, 0.15);;
			}
		}
	}
}

.ui-grid-pager-control .ui-grid-pager-max-pages-number {
	font-size: 11px;
	vertical-align: middle !important;
	padding: 0 5px;
}

.ui-grid-category {
	text-align: center;
	border-right: 0;
	box-shadow: -1px 1px #D4D4D4;
}

#annualPlaningGridView {
	&.small-grid {
		width: 499px;

		&.separated {
			width: 4099px;
		}
	}

	.ui-grid-pager-panel {
		width: 97.5vw;
	}
}

#comment_panel {
	.panel {
		width: 91.666%;
		float: left;
	}

	&.creditorsCommentPanel {
		.panel {
			width: 100%;
		}
	}
}

#project_typeahead, .company_typeahead {
	ul {
		overflow-y: auto;
		max-height: 200px;
		font-size: 11px;

		a {
			overflow: unset;
		}
	}
}

//[ui-grid-row] {
//	display: table-row;
//}
//
//.ui-grid-row, .ui-grid-cell {
////.ui-grid-row{
//	height: auto!important;
//}
//
//.ui-grid-cell {
//	float: none;
//	display: table-cell;
//}
//
//.ui-grid-header-cell, .ui-grid-cell-contents {
//	white-space: nowrap;
//	padding: 2px;
//	word-break: break-word;
//}

/* UI-Grid-Gedöns Ende*/

.rot90 {
	-ms-transform: rotate(90deg); /* IE 9 */
	-webkit-transform: rotate(90deg); /* Chrome, Safari, Opera */
	transform: rotate(90deg);
}

.rot180 {
	-ms-transform: rotate(180deg); /* IE 9 */
	-webkit-transform: rotate(180deg); /* Chrome, Safari, Opera */
	transform: rotate(180deg);
}

.rot270 {
	-ms-transform: rotate(270deg); /* IE 9 */
	-webkit-transform: rotate(270deg); /* Chrome, Safari, Opera */
	transform: rotate(270deg);
}

//.panel {
//	background-color: #FFF;
//	border: 1px solid transparent;
//	border-radius: 0;
//	box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
//	margin-bottom: 15px;
//}
//
//.panel-default {
//	border-color: #DDD;
//	& > .panel-heading {
//		background-color: #F5F5F5;
//		border-color: #DDD;
//		color: #333;
//	}
//}
//
//.panel-heading {
//	border-bottom: 1px solid transparent;
//	padding: 10px 15px;
//}
//
//.panel-title {
//	color: inherit;
//	font-size: 13px;
//	margin-bottom: 0;
//	margin-top: 0;
//}



body.stage {
	#login {
		& > h1 {
			& > a {
				background-image: @stage-logo-url;
			}
		}
	}
}

.form-control {
	&.search {
		width: 200px;
		//display: inline;
	}
}

/* Animations start*/
.fade {
	-webkit-transition: all @animation-duration ease-in-out;
	-moz-transition: all @animation-duration ease-in-out;
	-o-transition: all @animation-duration ease-in-out;
	transition: all @animation-duration ease-in-out;

	&.in {
		opacity: 1;
		-webkit-transition-delay: @animation-duration;
		-moz-transition-delay: @animation-duration;
		-o-transition-delay: @animation-duration;
		transition-delay: @animation-duration;
	}

	&.out {
		opacity: 0;
	}
}

@-webkit-keyframes bgColFadeOdd {

	from {
		background-color: @flash-bg;
	}
	to {
		background-color: @lightRow-bg;
	}
}

@-moz-keyframes bgColFadeOdd {
	from {
		background-color: @flash-bg;
	}
	to {
		background-color: @lightRow-bg;
	}
}

@-o-keyframes bgColFadeOdd {
	from {
		background-color: @flash-bg;
	}
	to {
		background-color: @lightRow-bg;
	}
}

@keyframes bgColFadeOdd {
	from {
		background-color: @flash-bg;
	}
	to {
		background-color: @lightRow-bg;
	}
}

.ui-grid-row {
	&:nth-child(2n+1) {
		.flashBG {
			.ui-grid-cell {
				background-image: none !important;
				-webkit-animation: bgColFadeOdd 2s ease-out;
				-moz-animation: bgColFadeOdd 2s ease-out;
				-o-animation: bgColFadeOdd 2s ease-out;
				animation: bgColFadeOdd 2s ease-out;
			}
		}
	}

	.weak {
		//color: lighten(@color-default, 30%);
		.ui-grid-cell-contents {
			opacity: 0.6;
		}
	}
}

@-webkit-keyframes bgColFadeEven {

	from {
		background-color: @flash-bg;
	}
	to {
		background-color: @darkRow-bg;
	}
}

@-moz-keyframes bgColFadeEven {
	from {
		background-color: @flash-bg;
	}
	to {
		background-color: @darkRow-bg;
	}
}

@-o-keyframes bgColFadeEven {
	from {
		background-color: @flash-bg;
	}
	to {
		background-color: @darkRow-bg;
	}
}

@keyframes bgColFadeEven {
	from {
		background-color: @flash-bg;
	}
	to {
		background-color: @darkRow-bg;
	}
}

.ui-grid-row:nth-child(2n) {
	.flashBG {
		.ui-grid-cell {
			background-image: none !important;
			-webkit-animation: bgColFadeEven 2s ease-out;
			-moz-animation: bgColFadeEven 2s ease-out;
			-o-animation: bgColFadeEven 2s ease-out;
			animation: bgColFadeEven 2s ease-out;
		}
	}
}

/* Animations end */

/* FONT AWESOME */
@font-face {
	font-family: "FontAwesome";
	font-style: normal;
	font-weight: 400;
	src: url("../fonts/fontawesome-webfont.eot?#iefix&v=4.4.0") format("embedded-opentype"),
	url("../fonts/fontawesome-webfont.woff2?v=4.4.0") format("woff2"),
	url("../fonts/fontawesome-webfont.woff?v=4.4.0") format("woff"),
	url("../../../css/fonts/fontawesome-webfont.ttf?v=4.4.0") format("truetype"),
	url("../fonts/fontawesome-webfont.svg?v=4.4.0#fontawesomeregular") format("svg");
}

.fa {
	display: inline-block;
	font-family: FontAwesome;
	font-feature-settings: normal;
	font-kerning: auto;
	font-language-override: normal;
	font-size: inherit;
	font-size-adjust: none;
	font-stretch: normal;
	font-style: normal;
	font-synthesis: weight style;
	font-variant: normal;
	font-weight: normal;
	line-height: 1;
	text-rendering: auto;

	&.viewSelector {
		margin-left: 10px;
		cursor: pointer;
		font-size: 24px;
		width: 24px;
	}

	&.activityState {
		font-size: 16px;
		width: 16px;

		&.fa-check, &.fa-sign-in {
			color: @color-active;
		}

		&.fa-times, &.fa-sign-out {
			color: @color-inactive;
		}
	}
}

[class^="fa fa-"] {
	font-size: 14px;
	vertical-align: middle;
}

.fa-power-off::before {
	content: "";
}

.fa-cogs::before, .fa-gears::before {
	content: "";
}

.ocrtext {
	width: 100%;
	//min-width: 870px;
	//max-width: 870px;
	min-height: 400px;
}

.fa-inline-button {
	font-size: 23px;
	margin-top: 1px;
	cursor: pointer;

	&.action-remove {
		color: @color-inactive;
	}

	&.action-add {
		color: @color-active;
	}
}

/* FORMS */
label {
	display: inline-block;
	font-weight: 700;
	margin-bottom: 5px;
	line-height: 24px;

	&.required {
		white-space: nowrap;

		&:after {
			content: "*";
		}
	}
}

.form-group {
	margin-bottom: 6px;
	display: flex;

	&.single-line {
		height: 24px;
	}

	&.viewMode {
		input[ng-currency] {
			text-align: right;
		}

		input {
			padding-left: 0;
		}

		.form-control, .input-group-addon {
			border: none;
			background-color: inherit;
			box-shadow: none;
			padding-right: 0;
			padding-left: 0;
		}

		.form-control[disabled], fieldset[disabled] .form-control {
			cursor: default;
			//span.select2-chosen {
			//	text-align: right;
			//	margin-right: 5px;
			//}
			&.algn-rght {
				span {
					&.select2-chosen {
						margin-right: 0;
					}
				}
			}
		}

		.input-group {
			.input-group-btn {
				display: none;
			}
		}

		.ui-select-toggle {
			display: none;
		}

		.select2-container.select2-container-disabled .select2-choice {
			background-color: inherit;
			border: none;
		}

		.select2-chosen {
			.hide-if-viewmode {
				display: none;
			}
		}

		.summernote-ro-text {
			border: none !important;
			background-color: inherit !important;
		}
	}

	span {
		&.clickable {
			text-decoration: underline;
			cursor: pointer;
		}
	}
}

.ui-select-bootstrap .ui-select-choices-row.active.disabled > span, .ui-select-bootstrap .ui-select-choices-row.disabled > span {
	color: #CCC;
}

.form-horizontal {
	.form-group {
		margin-left: -5px;
		margin-right: -5px;

		&::after, &::before {
			content: " ";
			display: table;
		}

		&::after {
			clear: both;
		}
	}

	.checkbox, .checkbox-inline, .control-label, .radio, .radio-inline {
		margin-bottom: 0;
		margin-top: 0;
		padding-top: 3px;
	}

	.control-label {
		text-align: right;
	}
}

textarea {
	&.noresize {
		resize: none;
	}
}

.has-error {
	.control-label {
		&.no-validation {
			color: inherit;
		}
	}

	.form-control {
		&.ng-valid {
			border-color: @border-color-neutral;

			&.error-override {
				border-color: @color-validation-error;
			}
		}

		.select2-choice {
			border-color: @color-validation-error;
		}
	}

	.input-group-btn {
		button {
			border-color: @color-validation-error;
			color: @color-validation-error;
		}
	}
}

form[name=creditorsForm], form[name=debitorsForm] {
	textarea {
		&.form-control {
			height: 95px;
		}
	}
}

output {
	&.note-status-output {
		padding-top: unset;
	}
}

fieldset {
	margin-bottom: 20px;

	&.letter {
		border: 1px solid @bg-neutral;
		padding: 10px;
		font-size: 11px;
		line-height: 22px;

		&.no-border {
			border: none;
		}

		.read-only-form-content {
			padding-bottom: 6px;
		}

		input {
			height: 24px;
		}

		.form-control {
			font-size: 11px;

			&.select2-container {
				vertical-align: bottom;
				margin: 10px 0;
			}

			&.no-vmargin {
				&.select2-container {
					margin: 0 5px;
				}
			}
		}

		label {
			font-size: 11px;
		}

		.tHeader {
			font-weight: bold;
			font-size: 11px;
		}

		ul {
			padding-left: 0;

			&.itemsList {
				padding: 10px 0;
				margin-bottom: 0;
			}
		}

		li {
			min-height: 20px;
			list-style: none;

			&.dndPlaceholder {
				margin-top: 5px;
				background-color: @darkRow-bg;
			}

			&.deleteMode {
				.handle {
					display: none !important;
				}

				.rowHandle {
					&::before {
						content: "\f1f8";
					}

					width: 9px;
					height: 22px;
					padding-top: 6px;
					cursor: pointer;
					font-size: 14px;
					vertical-align: middle;
					display: inline-block;
					font-family: FontAwesome;
					font-feature-settings: normal;
					font-kerning: auto;
					font-language-override: normal;
					font-size-adjust: none;
					font-stretch: normal;
					font-style: normal;
					font-synthesis: weight style;
					font-variant: normal;
					font-weight: normal;
					line-height: 1;
					text-rendering: auto;
				}
			}
		}

		.dynamicPositions {
			div {
				min-height: 10px;

				&.note-handle, &.note-icon-bar {
					min-height: 0;
				}

				&.summernote-ro-text {
					background-color: #EEE;
					border-radius: 4px;
					border: 1px solid #CCC;

					p {
						overflow-wrap: break-word;
					}
				}

			}

			.docItemRow {
				padding-left: 5px;
				padding-right: 5px;
				position: relative;
				margin: 5px 0;

				.rowHandle {
					position: absolute;
					left: 0;
					top: -1px;
				}

				.rowCheckbox {
					position: absolute;
					right: -5px;
				}
			}

			textarea {
				width: 296px;
				line-height: 1.4em;
			}

			.note-editable, .summernote-ro-text {
				line-height: 1.4em;
				padding: 3px 6px;

				ol {
					padding-left: 20px;

					li {
						list-style-type: decimal !important;
						list-style-position: outside;
						min-height: 0;
					}
				}

				ul {
					list-style-type: none;
					padding-left: 20px;

					li {
						min-height: 0;
						list-style: none;
						text-indent: -3px;

						&:before {
							content: "\2013 ";
							display: block;
							float: left;
							position: relative;
							left: -10px;
						}
					}
				}
			}

			.form-group {
				margin-bottom: 0;
			}

			.col-1 {
				width: 316px;
				padding-left: 15px;

				.itemText {
					line-height: 1.4em;
					padding-top: 5px;
				}
			}

			.col-1-single {
				width: 650px;
				padding-left: 15px;

				.panel {
					margin-bottom: 0;
				}
			}

			.col-2 {
				width: 97px;
			}

			.col-3 {
				width: 142px;
			}

			.col-4 {
				width: 87px;
			}

			.col-1, .col-2, .col-3, .col-4 {
				padding-right: 4px;
				float: left;
			}

			.col-2, .col-3, .col-4 {
				padding-left: 4px;
			}

			.col-2 {
				input {
					width: 89px;
					text-align: right;
				}

				.input-group {
					input {
						width: 68px;
					}
				}
			}

			.col-4 {
				input {
					width: 77px;
					text-align: right;
				}
			}

			.col-3 {
				input {
					width: 132px;
					text-align: right;
				}
			}

			input {
				width: 296px;
			}

			.input-group-addon {
				font-size: 11px;
				background-color: inherit;
				padding: 0 4px;
				cursor: pointer;
			}

			&.viewMode {
				.docItemRow {
					padding-left: 0;
				}

				.col-1 {
					width: 316px;
					padding-left: 0;

					input {
						padding-left: 0;
					}
				}

				.col-1-single {
					padding-left: 0;

					.summernote-ro-text {
						padding-left: 0;
					}
				}

				span {
					&.currencySymbol {
						margin-left: 3px;
						line-height: 24px;
					}
				}
			}
		}

		.dropdown-menu {
			font-size: 11px;
			padding: 3px 0;

			& > li > a {
				padding: 3px 10px;
			}
		}

		&.viewMode {

		}
	}
}

.dndDraggingSource {
	display: none;
}

li {
	dnd-nodrag {
		.handle {
			cursor: move;
		}
	}
}

.toolbox {
	ul {
		padding-left: 0;

		li {
			list-style: none;

			i {
				font-size: 24px;
			}
		}
	}

	.toolboxItem {
		width: 90px;
		line-height: 28px;
		font-size: 10px;
		border: 1px solid @bg-neutral;
		border-radius: 4px;
		text-align: center;
		vertical-align: middle;
		margin-bottom: 5px;
		cursor: move;
	}

	.trashcan {
		cursor: pointer;

		li {
			width: 32px;
			height: 32px;
			position: absolute;
			right: 0;
			background: url("../img/recycle-closed-icon.png");

			&.dndPlaceholder {
				display: none;
			}
		}

		&.dndDragover, &.deleteMode {
			li {
				background: url("../img/recycle-open-icon.png");
			}
		}
	}
}

.companyAddress {
	margin-bottom: 20px;
	display: flex;
}

.companyContact {
	margin-bottom: 20px;
	display: flex;
}

/* Customized Bootstrap start */

.btn {
	font-size: 12px;
	padding: 2px 4px 3px;
	vertical-align: top;

	[class^="fa fa-"] {
		margin-bottom: 1px;
	}
}

.btn-radios {
	.btn-primary {
		background-color: @color-inactive;
		border-color: @color-inactive;

		&.active, &:active {
			background-color: @color-active;
			border-color: @color-active;
		}
	}
}

.btn-group-xs > .btn, .btn-xs {
	padding: 0 5px;
}

.btn-default {
	&:hover {
		background-color: @light-bg;
		color: @color-on-dark-bg;
	}

	&:active, &:active:focus, &.active:focus, &:active:hover, &.focus:active {
		background-color: @dark-bg;
		color: @color-on-dark-bg;

		&.disabled, &[disabled] {
			background-color: inherit;
		}
	}

	&.disabled.focus,
	&.disabled:focus,
	&.disabled:hover,
	&.focus[disabled] {
		color: @color-default;
	}

	i {
		&.fa-arrow-up {
			margin-top: -3px;
		}

		&.fa-arrow-down {
			margin-top: -2px;
		}
	}
}

.btn-default[disabled] {
	color: @color-default;

	&:hover {
		color: @color-default;
	}
}

.form-control {
	border-radius: 4px;
	font-size: 12px;
	height: 24px;
	padding: 3px 6px;
}

ul {
	&.row {
		padding-left: 0;

		&.dashboard {
			li {
				list-style-type: none;

				.panel-heading {
					position: relative;
					background-color: @dark-bg;

					.panel-title {
						color: @color-on-dark-bg;
					}

					.handle {
						color: @color-on-dark-bg;
						position: absolute;
						top: -3px;
						left: 2px;
					}
				}
			}
		}
	}
}

.dashboard-table {
	padding-left: 0;

	h5 {
		//	clear: both;
		background-color: @light-bg;
		color: @color-on-dark-bg;
		padding: 5px 5px 7px 8px;

		.smaller {
			font-size: 11px;
		}
	}

	margin-bottom: 30px;

	& > div {
		//clear: both;
		&.tHeader {
			font-weight: bold;
		}

		& > div {
			//float: left;
			display: inline-block;
			padding: 5px 2px;

			&.col-1 {
				width: 25%;
				display: flex;

				&.debitors > div {
					&:first-child {
						width: 15%;
					}

					&:last-child {
						width: 85%;
					}
				}

				&.creditors > div {
					&:first-child {
						width: 25%;
					}

					&:last-child {
						width: 75%;
					}
				}
			}

			&.col-2 {
				width: 35%;

				&.debitors {
					display: flex;

					& > div:first-child {
						width: 12%;
					}

					& > div:last-child {
						padding-left: 5px;
						width: 88%;
					}
				}

				&.creditors {
					& > div {
						display: flex;

						& > div {
							&:first-child {
								width: 12%;
							}

							&:last-child {
								width: 88%;
							}
						}
					}
				}
			}

			&.col-3 {
				width: 10%;
			}

			&.col-4 {
				width: 18%;
			}

			&.col-5 {
				width: 12%;
			}
		}

		//&:last-child {
		//	clear: both;
		//}
		&.even {
			background-color: @darkRow-bg;
		}
	}
}

.editButton {
	position: absolute;
	right: -9px;
	top: -15px;
	cursor: pointer;
	opacity: 0.5;

	&:hover {
		opacity: 1;
	}
}

.inline-date-range-button {
	border-radius: 0;
	border-left: none;
	padding-left: 4px;
}

.panel-title {
	font-size: 12px;
	font-weight: bold;
}

.panel-heading {
	.panel-title {
		& > i.fa {
			float: right;
			font-size: 18px;
			margin-top: -2px;
			cursor: pointer;
		}
	}
}

// TODO löschen
//.panel-body {
//	.table-row {
//		padding: 4px 0;
//	}
//}

div.bootstrap-switch {
	//&.bootstrap-switch-focused {
	//	border-color: #ccc;
	//	box-shadow: none;
	//	outline: 0 none;
	//}
	&.bootstrap-switch-gender {
		span {
			&.bootstrap-switch-handle-on {
				background: #003399;
				color: #FFF;
			}

			&.bootstrap-switch-handle-off {
				background: #CC0000;
				color: #FFF;
			}
		}
	}

	&.bootstrap-switch-status {
		span {
			&.bootstrap-switch-handle-on {
				background: @color-active;
				color: #FFF;
			}

			&.bootstrap-switch-handle-off {
				background: @color-inactive;
				color: #FFF;
			}
		}
	}

	&.bootstrap-switch-yesno {
		.bootstrap-switch-container {
			width: 88px;
		}

		span {
			&.bootstrap-switch-handle-on {
				background: @color-active;
				color: #FFF;
			}

			&.bootstrap-switch-handle-off {
				background: @color-inactive;
				color: #FFF;
			}
		}
	}

	&.bootstrap-switch-role {
		span {
			&.bootstrap-switch-handle-on {
				background: #933;
				color: #FFF;
			}

			&.bootstrap-switch-handle-off {
				background: #666;
				color: #FFF;
			}
		}
	}

	.bootstrap-switch-label, .bootstrap-switch-handle-on, .bootstrap-switch-handle-off {
		font-size: 12px;
		line-height: 16px;
		padding: 3px 6px;
	}

	&.bootstrap-switch-animate .bootstrap-switch-container {
		-webkit-transition: margin-left @animation-duration;
		transition: margin-left @animation-duration;
	}

	&.bootstrap-switch-disabled .bootstrap-switch-handle-on,
	&.bootstrap-switch-readonly .bootstrap-switch-handle-on,
	&.bootstrap-switch-indeterminate .bootstrap-switch-handle-on,
	&.bootstrap-switch-disabled .bootstrap-switch-handle-off,
	&.bootstrap-switch-readonly .bootstrap-switch-handle-off,
	&.bootstrap-switch-indeterminate .bootstrap-switch-handle-off,
	&.bootstrap-switch-disabled .bootstrap-switch-label,
	&.bootstrap-switch-readonly .bootstrap-switch-label,
	&.bootstrap-switch-indeterminate .bootstrap-switch-label {
		cursor: default !important;
		opacity: 0.8;
	}
}

ul {
	&.help-block {
		padding-left: 0;

		li {
			list-style: none;
			margin: 0;

			&:last-child {
				margin-bottom: 10px;
			}
		}
	}

	&.no-padding-list {
		padding-left: 0;

		li {
			list-style: none;
		}
	}
}

.modal-header {
	background-color: @dark-bg;
	color: @color-on-dark-bg;
	padding: 8px 10px;

	.close {
		color: @color-on-dark-bg;
		opacity: 0.4;

		&:hover, &:focus {
			color: @color-on-dark-bg;
			opacity: 0.7;
		}
	}
}

hr {
	border-top: 1px solid #E5E5E5;
}

.transactionSelect {
	width: 200px;
}

.datePicker {
	width: 100px;

	.uib-datepicker-popup {
		padding: 5px;
		box-shadow: 0 6px 12px rgba(0, 0, 0, 0.6);
	}
}

.uib-daypicker {
	outline: none;

	.btn {
		padding: 4px 8px;
		font-weight: bold;

		&[disabled] {
			//font-weight: normal;
			opacity: .3;
		}
	}
}

.input-clear {
	position: absolute;
	right: 5px;
	z-index: 99;
	top: 2px;
	cursor: pointer;
}

.input-clear-wrapper {
	position: relative;

	span {
		position: absolute;
		right: 10px;
	}
}

div.datePicker {
	span {
		&.input-clear {
			right: 26px;
			top: 2px !important;
		}
	}
}

.dateRangePicker {
	span {
		&.input-clear {
			top: 4px;
		}
	}
}

.daterangepicker {
	.daterangepicker_input {
		display: none;
	}
}

.input-group {
	.form-control {
		&.datepicker-with-clear {
			width: 90px;
		}

		&.right-input {
			border-top-right-radius: 4px !important;
			border-bottom-right-radius: 4px !important;
		}
	}

	.form-control[readonly] {
		&.unaccessable {
			cursor: default;
			background-color: inherit;
		}
	}

	&.input-daterange {
		.input-group-addon {
			height: 24px;
			padding: 3px 15px;
			border-right: 0;
			border-left: 0;
		}

		.clear {
			position: absolute;
			cursor: pointer;
			z-index: 5;
			top: -1px;

			&.clear-left {
				left: 187px;
			}

			&.clear-right {
				left: 446px;
			}
		}
	}
}

.warning-box {
	padding: 2px;
	height: 28px;

	&.warn-active-filter {
		padding: 0;
		border: 2px solid #FF7700;
		border-radius: 6px;

		.form-control:focus {
			border-color: transparent;
			-webkit-box-shadow: none;
			box-shadow: none;
		}

		&.search-input {
			.input-clear {
				top: 3px;
			}
		}
	}

	span.input-clear {
		top: 5px !important;
	}
}

.scanStatusFilterSelect {
	width: 160px;
}

.dateRangeSelect {
	width: 90px;
}

.modal-footer {
	.btn {
		min-width: 60px;

		&.unlabeled {
			min-width: auto;
		}
	}
}

.modal-open {
	overflow: scroll;
}

.modal-backdrop {
	&.in {
		opacity: 0.5;
	}
}

.modal-fit {
	.modal-dialog {
		width: 98%;
		width: -moz-fit-content;
		//img {
		//width: 100%;
		//}
		&.modal-lg {
			.modal-header {
				cursor: move;
			}
		}
	}
}

.btn-add-prjct {
	position: absolute;
	right: -105px;
}

.modal-mgntp200 {
	.modal-dialog {
		margin-top: 200px;
	}
}

.modal-dialog {
	img {
		max-width: 100%;
	}
}

.modal-scan-right {
	&.modal {
		position: absolute;
		left: unset;
		top: 211px;
		height: 1000px;
		overflow-y: hidden;
		z-index: 990 !important;

		&.exported {
			top: 303px;
		}
	}

	.modal-dialog {
		margin: 10px 30px auto auto;
		width: 800px;
	}
}

.modal-chart {
	.modal-dialog {
		width: 98%;
	}

	.nav-tabs {
		margin-bottom: 15px;

		.nav-link {
			font-size: 12px;
			font-weight: bold;
		}
	}
}

.modal-xtralg {
	.modal-dialog {
		width: 1100px;
	}
}

legend {
	font-size: 16px;
	border: 0;
	background: @dark-bg;
	color: @color-on-dark-bg;
	padding: 5px 3px 2px 5px;
}

.legend {
	display: block;
	width: 100%;
	margin-bottom: 20px;
	font-size: 16px;
	line-height: 26px;
	border: 0;
	background: @dark-bg;
	color: @color-on-dark-bg;
	padding: 3px 3px 1px 5px;

	.header-addition {
		font-size: 11px;
	}

	a {
		&.white {
			color: @color-on-dark-bg;
		}
	}
}

.close:focus, .close:hover {
	color: @color-on-dark-bg;
}

// Animated checkboxes
.anim-checkbox {

	&.checkbox, &.radio {
		display: inline-block;
		margin-top: 0;

		label {
			padding: 4px;
			outline: none;

			input[type="radio"], input[type="checkbox"] {
				display: none;

				&:checked + .cr > .cr-icon {
					transform: scale(1) rotateZ(0deg);
					opacity: 1;
				}

				& + .cr > .cr-icon {
					transform: scale(3) rotateZ(-20deg);
					opacity: 0;
					transition: all .15s ease-in;
				}

				&:disabled + .cr {
					opacity: .5;
				}
			}

			&:after {
				content: '';
				display: table;
				clear: both;
			}

			&.disabled {
				cursor: default;;
			}
		}

		.cr {
			position: relative;
			display: inline-block;
			border: 1px solid #ADADAD;
			border-radius: .25em;
			width: 1.3em;
			height: 1.3em;
			float: left;
			//margin-right: .5em;
			.cr-icon {
				color: #333;
				position: absolute;
				font-size: .8em;
				line-height: 0;
				top: 50%;
				left: 15%;
			}
		}
	}

	&.radio {
		.cr {
			border-radius: 50%;

			.cr-icon {
				margin-left: 0.04em;
			}
		}
	}
}

/* Customized Bootstrap end*/

/* Customized Select2 start*/
.select2-container {
	//vertical-align: inherit;
	&.form-control {
		padding: 0;
		border: none;

		&.ui-select-container {
			text-align: left;

			.ui-select-dropdown {
				font-size: 11px;
			}
		}
	}

	.select2-drop {
		z-index: 999;
	}

	.select2-drop-active {
		border: 1px solid #CCC;
		border-top: none;
	}

	.select2-choice {
		height: 24px;
		line-height: 21px;
		border: 1px solid #CCC;

		.select2-arrow b {
			background: rgba(0, 0, 0, 0) url("../img/select2.png") no-repeat scroll 0 -2px;
		}

		&.select2-default {
			color: #444 !important;
		}

		abbr {
			background: url(../img/select2.png) right top no-repeat;
			top: 4px;
		}
	}

	.select2-results ul.select2-result-sub {
		padding-left: 10px;
	}

	.ui-select-choices {
		.fits {
			color: @color-active;
		}
	}

	.select2-results .select2-highlighted {
		background: #CCC;
	}
}

.viewMode {
	.select2-container {
		.select2-choice {
			padding-left: 0;
		}
	}
}

.ui-select-multiple {
	&.ui-select-container {
		//height: 24px;
		min-height: 24px;
		height: auto;
		padding: 1px 2px 0;

		&.flex-ui-select {
			& > div:first-child {
				display: flex;

				span.ui-select-match {
					display: flex;

					span.ui-select-match-close {
						margin-left: 4px;
					}
				}
			}
		}
	}

	input.ui-select-search {
		width: 100% !important;
	}

	&.ng-not-empty {
		input.ui-select-search {
			max-width: 94px;
		}
	}
}

.select2-search input {
	background-image: url("../img/select2.png");
}

/* workaround for dropdown container erroneously not showing up on multiple select */
.ui-select-container {
	&.open {
		.ui-select-dropdown {
			opacity: 1 !important;
		}
	}

	&.non-scrolling-choices {
		.select2-results {
			max-height: unset;
		}
	}
}

/* Customized Select2 end*/

/* Custom toasts start */

.toast-top-right {
	top: 36px;
	right: 8px;
}

.toast-container {
	z-index: 9999999;
}

.centeredToastr {
	text-align: center;
}

/* Custom toasts end */

/* Custom dialog/modal headers start */

.dialog-header-error {
	background-color: #D2322D;
}

.dialog-header-wait {
	background-color: @light-bg;
}

.dialog-header-notify {
	background-color: #EEEEEE;
}

.dialog-header-confirm {
	background-color: @dark-bg;
}

.modal-warning {
	.dialog-header-confirm {
		background-color: @warning-bg;
	}
}

.dialog-header-error span, .dialog-header-error h4,
.dialog-header-wait span, .dialog-header-wait h4,
.dialog-header-confirm span, .dialog-header-confirm h4 {
	color: @color-on-dark-bg;
}

.dialogs-backdrop-default {
	//z-index: 19999 !important;
}

//.modal {
//	&[index="0"] {
//		z-index: 20000 !important;
//	}
//	&[index="1"] {
//		z-index: 20010 !important;
//	}
//	&[index="2"] {
//		z-index: 20020 !important;
//	}
//	&[index="3"] {
//		z-index: 20030 !important;
//	}
//}

/* Custom dialog/modal headers end */

//input[type="search"]::-moz-placeholder, input[type="number"]::-moz-placeholder, input[type="text"]::-moz-placeholder, input[type="password"]::-moz-placeholder, input[type="datetime"]::-moz-placeholder, input[type="datetime-local"]::-moz-placeholder, input[type="date"]::-moz-placeholder, input[type="month"]::-moz-placeholder, input[type="time"]::-moz-placeholder, input[type="week"]::-moz-placeholder, input[type="email"]::-moz-placeholder, input[type="url"]::-moz-placeholder, input[type="tel"]::-moz-placeholder, input[type="color"]::-moz-placeholder, select::-moz-placeholder, textarea::-moz-placeholder {
//	color: #999;
//	opacity: 1;
//}

//input[type="search"], input[type="number"], input[type="text"], input[type="password"], input[type="datetime"], input[type="datetime-local"], input[type="date"], input[type="month"], input[type="time"], input[type="week"], input[type="email"], input[type="url"], input[type="tel"], input[type="color"], select, textarea {
//	background-color: #FFF;
//	background-image: none;
//	border: 1px solid #CCC;
//	border-radius: 0;
//	box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset;
//	color: #555;
//	display: block;
//	font-size: 11px;
//	height: 21px;
//	line-height: 1.42857;
//	padding: 2px;
//	transition: border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s;
//	vertical-align: middle;
//	width: 100%;
//}

//button, input {
//	line-height: normal;
//}
//
//button, input, select, textarea {
//	font-family: inherit;
//	font-size: 100%;
//	margin: 0;
//}
//
//.btn {
//	-moz-user-select: none;
//	background-image: none;
//	border: 1px solid transparent;
//	border-radius: 0;
//	cursor: pointer;
//	display: inline-block;
//	font-size: 11px;
//	font-weight: 400;
//	line-height: 1.42857;
//	margin-bottom: 0;
//	padding: 2px;
//	text-align: center;
//	vertical-align: middle;
//	white-space: nowrap;
//	&:first-child {
//		margin-left: 0;
//	}
//	&:last-child {
//		margin-right: 0;
//	}
//}
//
//.btn-primary {
//	background-color: #001E63;
//	border-color: #00164A;
//	color: #FFF;
//}
//
//.btn-primary.active, .btn-primary:active, .btn-primary:focus, .btn-primary:hover, .open .dropdown-toggle.btn-primary {
//	background-color: #00123A;
//	border-color: #00040C;
//	color: #FFF;
//}
//
//.btn-lg {
//	border-radius: 0;
//	font-size: 14px;
//	line-height: 1.33;
//	padding: 4px;
//}
//
//button, html input[type="button"], input[type="reset"], input[type="submit"] {
//	cursor: pointer;
//}
//
//.help-block {
//	color: #737373;
//	display: block;
//	margin-bottom: 10px;
//	margin-top: 5px;
//}
//
//.col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-xs-1, .col-xs-10, .col-xs-11, .col-xs-12, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9 {
//	min-height: 1px;
//	padding-left: 5px;
//	padding-right: 5px;
//	position: relative;
//}
//
//.row {
//	margin-left: -5px;
//	margin-right: -5px;
//}
//
//
//p {
//	margin: 0 0 1em 0;
//}
//
//code {
//	background: #F5F5F5;
//	max-width: 100px;
//	padding: 2px 6px;
//	word-wrap: break-word;
//}
//
//#wrapper {
//	background: #FFF;
//	margin: 1em auto;
//	max-width: 800px;
//	width: 95%;
//}
//
//#container {
//	padding: 2em;
//}
//
//#welcome, #status {
//	margin-bottom: 2em;
//}
//
//#welcome h1 span {
//	display: block;
//	font-size: 75%;
//}
//
//#icon-status, #icon-book {
//	float: left;
//	height: 64px;
//	margin-right: 1em;
//	margin-top: -4px;
//	width: 64px;
//}
//
//#icon-book {
//	display: none;
//}

.ir {
	background-color: transparent;
	border: 0 none;
	overflow: hidden;

	&::before {
		content: "";
		display: block;
		height: 150%;
		width: 0;
	}
}

.lt-ie8 .ir {
	text-indent: -9999px;
}

//
//@media (min-width: 768px) {
//	#wrapper {
//		width: 80%;
//		margin: 2em auto;
//	}
//
//	#icon-book {
//		display: inline-block;
//	}
//
//	#status a, #next a {
//		display: block;
//	}
//
//	@-webkit-keyframes fade-in {
//		0% {
//			opacity: 0;
//		}
//		100% {
//			opacity: 1;
//		}
//	}
//	@keyframes fade-in {
//		0% {
//			opacity: 0;
//		}
//		100% {
//			opacity: 1;
//		}
//	}
//	.sf-toolbar {
//		opacity: 0;
//		-webkit-animation: fade-in 1s .2s forwards;
//		animation: fade-in 1s .2s forwards;
//	}
//}

/* Toggle swich */
.ats-switch {
	border: 1px solid;
	cursor: pointer;
	display: inline-block;
	text-align: left;
	overflow: hidden;
	line-height: 8px;
	min-width: 100px;
	outline: none;
	display: inline-block;
	cursor: pointer;
	border-radius: 4px;
	border: 1px solid;
	border-color: #CCC;
	position: relative;
	text-align: left;
	overflow: hidden;
	line-height: 8px;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	-o-user-select: none;
	user-select: none;
	vertical-align: middle;
	-webkit-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
	transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;

	&.disabled {
		opacity: 0.5;
		filter: alpha(opacity=50);
		cursor: default !important;

		.switch-left,
		.switch-right,
		.knob {
			cursor: default !important;
		}

		&:hover {
			border-color: #CCC;
			-webkit-box-shadow: inset 0 0 0 rgba(0, 0, 0, 0), 0 0 0 rgba(0, 0, 0, 0);
			box-shadow: inset 0 0 0 rgba(0, 0, 0, 0), 0 0 0 rgba(0, 0, 0, 0);
		}
	}

	span {
		cursor: pointer;
		display: inline-block;
		float: left;
		height: 100%;
		line-height: 20px;
		padding: 4px;
		text-align: center;
		width: 40%;
		white-space: nowrap;
		box-sizing: border-box;
		-o-box-sizing: border-box;
		-moz-box-sizing: border-box;
		-webkit-box-sizing: border-box;

		&.switch-left {
			color: #FFF;
			background: #428BCA;
		}

		&.switch-right {
			color: #000;
			background: #EEEEEE;
		}

		&.knob {
			border-left: 1px solid #CCC;
			border-right: 1px solid #CCC;
			width: 20%;
			z-index: 100;
			border-right: none;
			border-left: none;
			text-align: center;
			margin-top: -1px;
			margin-bottom: -1px;
			background: #FFF;

			i {
				color: #000;
				text-shadow: 0 1px 0 #FFF;
				line-height: 18px;
				pointer-events: none;
			}
		}
	}

	& > div {
		position: relative;
		width: 180%;
	}

	.switch-on {
		left: 0%;
	}

	.switch-off {
		left: -78%;
	}

	.swtich-left,
	.switch-right {
		z-index: 1;
	}

	.switch-left {
		color: #FFF;
		background: #005FCC;
	}

	.switch-right {
		color: #333;
		background: #F0F0F0;
	}

	.switch-animate {
		transition: left @animation-duration;
		-o-transition: left @animation-duration;
		-moz-transition: left @animation-duration;
		-webkit-transition: left @animation-duration;
	}

	&:hover {
		border-color: #66AFE9;
		outline: 0;
		-webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(102, 175, 233, 0.6);
		box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(102, 175, 233, 0.6);
	}

	.switch-left,
	.switch-right,
	.knob {
		min-height: 28px;
	}

	&.switch-mini {
		min-width: 72px;

		.switch-left,
		.switch-right,
		.knob {
			padding-bottom: 4px;
			padding-top: 4px;
			font-size: 10px;
			line-height: 9px;
			min-height: 18px;
		}

		i.switch-mini-icons {
			height: 1.20em;
			line-height: 9px;
			vertical-align: text-top;
			text-align: center;
			transform: scale(0.6);
			margin-top: -1px;
			margin-bottom: -1px;
		}
	}

	&.switch-small {
		min-width: 58px;
		height: 24px;

		.switch-left,
		.switch-right,
		.knob {
			padding-bottom: 3px;
			padding-top: 3px;
			font-size: 11px;
			line-height: 16px;
			min-height: 22px;
		}
	}

	&.switch-large {
		min-width: 120px;

		.switch-left,
		.switch-right,
		.knob {
			padding-bottom: 9px;
			padding-top: 9px;
			font-size: 16px;
			line-height: normal;
			min-height: 34px;
		}
	}

	&.switch-primary span.switch-left {
		color: #FFF;
		background: #428BCA;
	}

	&.switch-info span.switch-left {
		color: #FFF;
		background: #5BC0DE;
	}

	&.switch-success span.switch-left {
		color: #FFF;
		background: #5CB85C;
	}

	&.switch-green-red span {
		color: #FFF;

		&.switch-left {
			background: @color-active;
		}

		&.switch-right {
			background: @color-inactive;
		}
	}

	&.switch-grey-green span {
		color: #FFF;

		&.switch-left {
			background: @bg-neutral;
		}

		&.switch-right {
			background: @color-active;
		}
	}

	&.switch-green-grey span {
		color: #FFF;

		&.switch-left {
			background: @color-active;
		}

		&.switch-right {
			background: @bg-neutral;
		}
	}

	&.switch-blue-red span {
		color: #FFF;

		&.switch-left {
			background: @dark-bg;
		}

		&.switch-right {
			background: @color-inactive;
		}
	}

	&.switch-role span {
		color: #FFF;

		&.switch-left {
			background: #933;
		}

		&.switch-right {
			background: #666;
		}
	}

	&.switch-warning span.switch-left {
		background: #F0AD4E;
		color: #FFF;
	}

	&.switch-danger span.switch-left {
		color: #FFF;
		background: #D9534F;
	}

	&.switch-default span.switch-left {
		color: #000;
		background: #EEEEEE;
	}
}

.switch-group {
	& > span {
		line-height: 24px;
		margin-right: 5px;
	}
}


.grid.ui-grid-flex {
	.ui-grid-canvas {
		height: auto !important;

		.ui-grid-row > div[ui-grid-row] > div {
			display: flex;

			.ui-grid-cell {
				height: auto !important;
			}
		}
	}
}

/* drag & drop upload */
.upload-container {
	position: relative;
	display: block;
	margin: 30px 20px;

	.upload-wrapper {
		display: block;
		border: 4px dashed #c3c4c7;
		padding: 20px;

		&.highlight {
			border-color: #e14d43;
		}
	}

	.upload-info {
		text-align: center;
	}

	h2 {
		text-align: center;
	}

	.btn-file-input {
		position: relative;
		overflow: hidden;
		min-height: 46px;
		font-size: 16px;
		margin: 12px auto;
		color: #e14d43;
		border-color: #e14d43;
		padding: 0 36px;
		line-height: 3.667;

		input[type=file] {
			position: absolute;
			display: block;
			top: 0;
			right: 0;
			width: 100%;
			height: 100%;
			font-size: 100px;
			text-align: right;
			filter: alpha(opacity=0);
			opacity: 0;
			outline: none;
			background: white;
			cursor: inherit;
		}

		&:hover {
			background-color: #6b6b6b;
		}
	}

	p {
		text-align: center;
		margin-bottom: 10px;
	}

	.upload-progress {
		.progress-wrapper {
			margin: 10px 0;
		}

		.progress {
			margin: 0;
		}
	}
}

.uploadPreviewContainer {
	display: flex;
	flex-wrap: wrap;
	flex-direction: row;
	margin-bottom: 30px;

	.uploadPreview {
		display: block;
		width: 220px;
		height: auto;
		border: 2px solid #808080;
		border-radius: 5px;
		padding: 6px;
		margin: 10px;

		&.small-preview {
			width: 150px;

			.upload-head {
				display: block;
			}
		}
	}

	.upload-head {
		display: flex;
		flex-wrap: nowrap;
		justify-content: space-between;
		word-break: break-all;
	}

	.btn {
		width: 24px;
		height: 24px;
		text-align: center;
		margin-left: 5px;
	}

	.check-mark {
		font-size: 28px;
		line-height: 0.9;
		cursor: pointer;

		&.fa-check-square {
			color: @color-active;
		}
	}
}

.dashbutton-area {
	display: flex;
	flex-wrap: wrap;

	.btn {
		margin-left: 10px;
		margin-bottom: 6px;
	}
}

@import "bootstrap-custom-button";
@import "loading-spinner";
@import "image-slideshow";
@import "login";
